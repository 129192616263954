import styles from "./NotFound.module.scss";
import gears from "media/gears.svg";

import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { Container, Row, Col, Button } from "react-bootstrap";

import { AppContext } from "index.js";

export default function NotFound({ locale }) {
  const appContext = useContext(AppContext);

  return (
    <div
      className={classNames(
        styles.component,
        "d-flex justify-content-center align-items-center"
      )}
    >
      <Helmet>
        <title>
          {appContext.intl.formatMessage({
            id: "NotFound.pageTitle",
            defaultMessage: "404 Not Found",
          })}
        </title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12">
            <h1 className="display-3 mb-0">
              <FormattedMessage
                id="NotFound.title"
                defaultMessage="404 Not Found"
              />
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="10" sm="8" md="7" lg="6" xl="5">
            <img
              className={classNames(
                styles.gearsImg,
                "img-fluid my-5 my-sm-3 my-md-4"
              )}
              src={gears}
              alt="Gears"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="10" lg="8" xl="7">
            <p>
              <FormattedMessage
                id="NotFound.text"
                defaultMessage="The requested page does not exist. Please make sure that you entered the correct URL. Or you can navigate to our homepage to find what you need."
              />
            </p>
            <p>
              <Button variant="primary" size="lg" href="/">
                <FormattedMessage
                  id="NotFound.button"
                  defaultMessage="Go to Homepage"
                />
              </Button>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
